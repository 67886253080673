const AppSpecimenRouter = [{
    path: '/specimenReception',
    name: 'specimenReception',
    component: () => import('./SpecimenReception.vue')
}, {
    path: '/sceneSpecimenList',
    name: 'sceneSpecimenList',
    component: () => import('./SceneSpecimenList.vue')
}, {
    path: '/sceneSpecimenResultSave',
    name: 'sceneSpecimenResultSave',
    component: () => import('./SceneSpecimenResultSave.vue')
}, {
    path: '/sceneSpecimenResultShow',
    name: 'sceneSpecimenResultShow',
    component: () => import('./SceneSpecimenResultShow.vue')
}
];
const H5SpecimenRouter = [{
    path: '/sceneSpecimenListH5',
    name: 'sceneSpecimenListH5',
    component: () => import('./SceneSpecimenList.vue')
}, {
    path: '/specimenReceptionH5',
    name: 'specimenReceptionH5',
    component: () => import('./SpecimenReception.vue')
}, {
    path: '/sceneSpecimenResultSaveH5',
    name: 'sceneSpecimenResultSaveH5',
    component: () => import('./SceneSpecimenResultSave.vue')
}, {
    path: '/sceneSpecimenResultShowH5',
    name: 'sceneSpecimenResultShowH5',
    component: () => import('./SceneSpecimenResultShow.vue')
}];

const H5SpecimenRouter2 = [{
    path: '/sceneSpecimenResultSaveH5',
    name: 'sceneSpecimenResultSaveH5',
    component: () => import('./SceneSpecimenResultSave.vue')
}];

export { AppSpecimenRouter, H5SpecimenRouter, H5SpecimenRouter2 };
