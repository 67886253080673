const AppReportRouter = [{
    path: '/examineReport',
    name: 'examineReport',
    component: () => import('./ExamineReport.vue')
}, {
    path: '/sendReportlist',
    name: 'sendReportlist',
    component: () => import('./SendReportlist.vue')
}, {
    path: '/archivedReportList',
    name: 'archivedReportList',
    component: () => import('./ArchivedReportList.vue')
}];
const H5ReportRouter = [{
    path: '/examineReportH5',
    name: 'examineReportH5',
    component: () => import('./ExamineReport.vue')
}, {
    path: '/sendReportlistH5',
    name: 'sendReportlistH5',
    component: () => import('./SendReportlist.vue')
}, {
    path: '/archivedReportListH5',
    name: 'archivedReportListH5',
    component: () => import('./ArchivedReportList.vue')
}];

export { AppReportRouter, H5ReportRouter };
