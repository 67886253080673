const H5DemoRouter = [{
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('./UserInfo.vue')
}, {
    path: '/userCollectGoods',
    name: 'userCollectGoods',
    component: () => import('./UserCollectGoods.vue')
}, {
    path: '/locphodemo',
    name: 'locphodemo',
    component: () => import('./LoctionPhotoDemo.vue')
}];

export { H5DemoRouter };
