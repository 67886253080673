<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
#app {
  margin: 0;
  padding: 0;
  font-size: $font-size;

  .van-popup__close-icon--top-right {
    top: 10px !important;
  }
  .van-field__error-message {
    text-align: right;
  }
}
</style>
