import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { H5DemoRouter } from '@/views/demo/router';
import { AppSpecimenRouter, H5SpecimenRouter,H5SpecimenRouter2 } from '@/views/specimen/router';
import { AppSampleRouter, H5SampleRouter } from '@/views/sample/router';
import { AppReportRouter, H5ReportRouter } from '@/views/report/router';
import { AppInconWorkRouter,H5InconWorkRouter } from '@/views/qualityManage/router';

let arrRouter: any[] = [];
arrRouter = arrRouter.concat(H5SpecimenRouter);
arrRouter = arrRouter.concat(H5SampleRouter);
arrRouter = arrRouter.concat(H5DemoRouter);
arrRouter = arrRouter.concat(H5ReportRouter);
arrRouter = arrRouter.concat(H5InconWorkRouter);

let routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("../views/UserLogin.vue"),
  },
  {
    path: "/userlogin",
    component: () => import("../views/UserLogin.vue"),
  },
  {
    path: "/webviewdemo",
    component: () => import("../views/WebViewDemo.vue"),
  },
  {
    path: "/userhome",
    name: "userhome",
    component: () => import("../views/UserHome.vue"),
    redirect: arrRouter[0].path,
    children: arrRouter
  }
];
routes = routes.concat(AppSampleRouter);
routes = routes.concat(AppSpecimenRouter);
routes = routes.concat(AppReportRouter);
routes = routes.concat(AppInconWorkRouter);
routes = routes.concat(H5SpecimenRouter2);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
