const AppInconWorkRouter = [{
  path: '/inconWorkList',
  name: 'inconWorkList',
  component: () => import('./InconWorkList.vue')
},
{
  path:'/inconWorkEstablish',
  name:'inconWorkEstablish',
  component:()=>import('./InconWorkEstablish.vue')
}];
const H5InconWorkRouter = [{
  path: '/inconWorkListH5',
  name: 'inconWorkListH5',
  component: () => import('./InconWorkList.vue')
},
{
  path:'/inconWorkEstablishH5',
  name:'inconWorkEstablishH5',
  component:()=>import('./InconWorkEstablish.vue')
}];

export { AppInconWorkRouter, H5InconWorkRouter };