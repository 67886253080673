const AppSampleRouter = [{
    path: '/sampleReception',
    name: 'sampleReception',
    component: () => import('./SampleReception.vue')
},{
    path: '/sampleCollect',
    name: 'sampleCollect',
    component: () => import('./SampleCollect.vue')
}];
const H5SampleRouter = [{
    path: '/sampleReceptionH5',
    name: 'sampleReceptionH5',
    component: () => import('./SampleReception.vue')
},{
    path: '/sampleCollectH5',
    name: 'sampleCollectH5',
    component: () => import('./SampleCollect.vue')
}];

export { AppSampleRouter, H5SampleRouter };
