import { createStore } from "vuex";

export default createStore({
  strict: true,  // 开启严格模式  确保state 中的数据只能 mutations 修改
  state: {
    accessToken: localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken') || '{}') : null,
    openId: localStorage.getItem('openId') ? JSON.parse(localStorage.getItem('openId') || '{}') : null,
    // 用户登录信息
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') || '{}') : null,
    //是否自动登录
    autoLogin: localStorage.getItem('autoLogin') ? JSON.parse(localStorage.getItem('autoLogin') || '{}') : null,
    collectInfo: localStorage.getItem('collectInfo') ? JSON.parse(localStorage.getItem('collectInfo') || '{}') : null,
    goodsInfo: localStorage.getItem('goodsInfo') ? JSON.parse(localStorage.getItem('goodsInfo') || '{}') : null
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setOpenID(state, openId) {
      state.openId = openId;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    //设置用户当前切换的部门
    setUserCurDept(state, dept) {
      state.userInfo.curDept = dept;
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    //设置用户所有部门
    setUserDepts(state, depts) {
      state.userInfo.depts = depts;
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    setUserOpenId(state, openId) {
      state.userInfo.openId = openId;
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    //自动登录
    setAutoLogin(state, autoLogin) {
      state.autoLogin = autoLogin;
    }
  },
  actions: {
    setAccessToken({ commit }, accessToken) {
      localStorage.setItem('accessToken', JSON.stringify(accessToken));
      commit('setAccessToken', accessToken);
    },
    setOpenID({ commit }, openId) {
      localStorage.setItem('openId', JSON.stringify(openId));
      commit('setOpenID', openId);
    },
    setToken({ commit }, token) {
      localStorage.setItem('token', token);
      commit('setToken', token);
    },
    setUserInfo({ commit }, userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      commit('setUserInfo', userInfo);
    },
    setUserCurDept({ commit }, dept) {
      commit('setUserCurDept', dept);
    },
    setUserDepts({ commit }, depts) {
      commit('setUserDepts', depts);
    },
    setUserOpenId({ commit }, openId) {
      commit('setUserOpenId', openId);
    },
    setSignOut({ commit }) {
      localStorage.removeItem('token');
      commit('setToken', '');
      localStorage.removeItem('userInfo');
      commit('setUserInfo', '');
      localStorage.removeItem('autoLogin');
      commit('setAutoLogin', '');
      localStorage.removeItem('collectInfo');
      localStorage.removeItem('goodsInfo');
      localStorage.removeItem('openId');
    },
    setAutoLogin({ commit }, autoLogin) {
      localStorage.setItem('autoLogin', JSON.stringify(autoLogin));
      commit('setAutoLogin', autoLogin);
    },
    setCollectInfo({ commit }, collectInfo) {
      localStorage.setItem('collectInfo', JSON.stringify(collectInfo));
    },
    setCollectGoods({ commit }, goodsInfo) {
      localStorage.setItem('goodsInfo', JSON.stringify(goodsInfo));
    }
  }
});
