import '@babel/polyfill';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "./api";
import VueAxios from "vue-axios";

import { Image } from "vant";
import { Button } from "vant";
import { Field } from "vant";
import { Cell, CellGroup, Divider } from "vant";
import { Picker } from "vant";
import { Popup } from "vant";
import { Form } from "vant";
import { DatetimePicker } from "vant";
import { RadioGroup, Radio } from "vant";
import { Tabbar, TabbarItem } from 'vant';
import { Col, Row } from 'vant';
import { ContactCard } from 'vant';
import { ActionBar, ActionBarIcon, ActionBarButton } from 'vant';
import { Empty } from 'vant';
import { Toast, Dialog, Notify, Overlay } from 'vant';
import { Tab, Tabs } from 'vant';
import { Icon } from 'vant';
import { List } from 'vant';
import { Switch } from 'vant';
import { Tag } from 'vant';
import { PullRefresh } from 'vant';
import { Grid, GridItem } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Uploader } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { SubmitBar } from 'vant';
import { Cascader } from 'vant';
// Toast.setDefaultOptions({ duration: 2000 });

createApp(App)
  .use(VueAxios, Axios)
  .use(Image)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(SubmitBar)
  .use(Button)
  .use(Field)
  .use(Cell)
  .use(CellGroup)
  .use(Picker)
  .use(Popup)
  .use(Form)
  .use(DatetimePicker)
  .use(RadioGroup)
  .use(Radio)
  .use(store)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Col)
  .use(Row)
  .use(ContactCard)
  .use(ActionBar)
  .use(ActionBarIcon)
  .use(ActionBarButton)
  .use(Empty)
  .use(Toast)
  .use(Dialog)
  .use(Notify)
  .use(Overlay)
  .use(Divider)
  .use(Tab)
  .use(Tabs)
  .use(Icon)
  .use(List)
  .use(Switch)
  .use(Tag)
  .use(PullRefresh)
  .use(Grid)
  .use(GridItem)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Uploader)
  .use(router)
  .use(Cascader)
  .mount("#app");
